import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c2a66bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "service" }
const _hoisted_2 = { class: "agreement-wrapper" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader2 = _resolveComponent("BaseHeader2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader2, {
      borderBottomColor: 
        _ctx.$isDarkMode() ? 'var(--grey3-color)' : 'var(--grey2-color)'
      ,
      borderBottomHeight: "1px"
    }, {
      "slot-middle": _withCtx(() => [
        _createTextVNode("개인정보취급방침")
      ]),
      _: 1
    }, 8, ["borderBottomColor"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { innerHTML: _ctx.agreement }, null, 8, _hoisted_3)
    ])
  ]))
}