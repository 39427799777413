
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Service",
  components: {
    BaseHeader2,
  },
  data() {
    const store = useStore();
    return { store, agreement: "" };
  },
  props: {
    menuTitle: {
      type: String,
    },
  },
  async mounted() {
    const agreement: { agreement: string } = await this.$axios
      .get("/sitemeta/agreement/privacy")
      .then((res) => {
        return res.data;
      });
    this.agreement = agreement.agreement;
  },
  methods: {},
});
